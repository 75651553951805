.pagination{
  display: flex;
  justify-content: center;
  padding: 0;
}

.pagination-item{
  list-style: none;
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding: 1.2rem 1.6rem;
  cursor: pointer;
  transition: all 0.3s;
  font-size: 1.4rem;
  color: darkslategray;
}

.pagination-item:first-child{
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.pagination-item:last-child{
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.pagination-link{
  text-decoration: none;
  font-size: 1.4rem;
  color: darkslategray;
  transition: all 0.3s;
}

.pagination-item:hover{
  color: white;
  background-color: #602E84;
  font-weight: 600;
}

.pagination-item:hover .page-link{
  color: white;
}

.pagination-item-active{
  background-color: #602E84;  
}

.pagination-link-active{
  color: white !important;
  font-weight: 600;
}

.input-container {
  position: relative;  
}

.input-field {
  width: 100%;
  padding: 1.5rem !important;
  border: 1px solid #e2e2e2 !important;
  border-radius: 8px !important;
  transition: border-color 0.2s ease-in-out;
  background-color: white !important;
  min-height: 5rem !important;
}

.input-field:disabled {
  background-color: #f5f5f5 !important;
  cursor: not-allowed;
}

.input-field--focused {
  border-color: #2e3192 !important;
}

.input-label {
  position: absolute;
  top: 50%;
  left: 1.5rem;
  transform: translateY(-50%);
  padding: 0 5px;
  font-size: 14px;
  color: #999;
  transition: all 0.2s ease-in-out;
  pointer-events: none;
}

.input-label--textarea {
  top: 25px !important;    
}

.input-label--focused {
  top: 0 !important;
  left: 0 !important;
  transform: translate(10px, -50%);
  font-size: 12px;
  color: #2e3192 !important;    
  background-color: white !important;
}