html,
body {
  padding: 0;
  margin: 0;  
  max-width: 192rem;
  margin-left: auto;
  margin-right: auto;    
}

html {
  /* Set 1rem to the browser default (10px) */
  font-size: 62.5%;
  overflow-x: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.border-white {
  border-color: white !important;
}

/* form input css */
input[type="text"],
input[type="email"],
input[type="password"],
input[type="search"],
input[type="number"],
input[type="date"],
input[type="time"],
textarea {
  display: block;
  width: 100%;
  padding: 0.5rem 3rem;
  font-size: 1.4rem;
  line-height: 1.8rem;  
  font-weight: 500;
  border-radius: 0.4rem;
  border: 1px solid #DEE1E6;
  margin-top: 1rem;  
  resize: vertical;
  outline: none;
  color: #141414;
  /* min-width: 16rem; */
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="number"],
input[type="date"], 
input[type="search"],
input[type="time"]{
  height: 4rem;
}

/* SAFARI FIX */
input[type="date"]{
  min-width:95% !important;  
}

.form-input-select{  
	height: 4rem;
  padding: 0.5rem 1rem;  
  border: 1px solid #DEE1E6;
  border-radius: 0.4rem;
  outline: none;
  font-size: 1.4rem;
  line-height: 1.8rem;
  min-width: 16rem;
}

::-webkit-input-placeholder {
  color: #B3B3B3;
  font-weight: 300;
  font-size: 1.4rem;
  line-height: 1.8rem;  
}

.error {
  color: red;
  position: absolute;
  right: 0;
  font-size: 1rem;  
}

.input-error {
  border: 1px solid;
  border-color: red !important;
}

::-webkit-scrollbar{
  width: 5px;
  height: 6px;
}

::-webkit-scrollbar-track{
  box-shadow: inset 0 0 5px #a5aaad;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb{
  background: #602E84;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover{
  background: #a5aaad;
}

.sidebar-option-icon > svg > path{  
  stroke: inherit !important;
}

.active > div > div > div {
  color: #602E84 !important;
}

.active > div{
  background-color: #dde8f9 !important;
  border-radius: 0 2rem 2rem 0 !important;
}